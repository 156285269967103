<!--
******************************************************************
*   1.0.0 - 08/01/2022 - init file - CM
*   1.0.1 - 30/01/2022 - chnaged background colour to shade of grey - CM
*
*
*   file - App.vue
*   dependencies - NONE
*
*
******************************************************************-->
<template>
  <Nav/>
  <router-view/>
</template>

<style lang="scss">
@font-face {
  font-family: "Overpass";
  src: local("Overpass"),
   url('assets/fonts/Overpass-Black.ttf') format("truetype");
}
body{
  margin: 0;
  background-color: #f5f5f5; //1.0.1
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
import Nav from '@/components/Navigation.vue';
export default {
  name:'App',
  components:
  {
    Nav
  }
}
</script>