<!--
******************************************************************
*   1.0.0 - 08/01/2022 - init file - CM
*   1.0.1 - 08/01/2022 - added book now button - CM
*   1.0.1 - 28/01/2022 - added h3 - CM
*
*
*   file - Home.vue
*   dependencies - NONE
*
*
******************************************************************-->
<template>
  <div class="uc-outer">
        <picture>
            <!-- <source media="(min-width: 650px)" srcset="../assets/images/419.png"> -->
            <img src="@/assets/images/419-1080.webp" alt="background">
        </picture>
        <div class="colour-overlay">
        </div>
        <div class="uc-text">
            <h1>Fairview Tourist Park</h1>
            <h3>Family ran for over 45 years</h3> <!--1.0.2-->
            <router-link to="/book"><button type="button" class="uc-button">Book Now!</button></router-link> <!-- 1.0.1 -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeComp'
}
</script>

<style lang="scss">

    .uc-outer
    {
        width: 100%;
        position: fixed;
    }
    .uc-text
    {
        color: white;
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translate(-50%, -50%);
        z-index: 5;
        font-family: "Overpass";
        font-size: 4em;
        @media (max-width:1480px){
            font-size: 3em;
        }
                @media (max-width:425px){
            font-size: 2em;
        }
    }
    .uc-button //1.0.1
    {
        background: yellowgreen;
        border: #9acd32;
        border-radius: 25px;
        height: 40px;
        width: 200px;
        font-family: "Overpass";
        font-size: 15px;
        color: white;
        cursor: pointer;
        transition: 300ms;
        &:hover
        {
            background: #59791a;
            border: #59791a;
        }
    }
    .background
    {
        max-height: 100vh ;
        
    }

    img
    {
        width: 100%;
        height: auto;
        @media (max-width:1125px) 
        {
            height: 100vh;
            width: auto;        
        }
    }

    .colour-overlay
    {
        width: 100%;
        height: 100vh;
        background-color: black;
        opacity: 0.75;
        position: absolute;
        top: 0;
    }
    h3{
        font-size: 20px;
    }
</style>