<!--
******************************************************************
*   1.0.0 - 08/01/2022 - init file - CM
*   1.0.1 - 08/01/2022 - added placehodler logo and style for logo - CM
*   1.0.2 - 28/01/2022 - added hamburger Menu - CM
*   1.0.2 - 28/01/2022 - added icon change on close() - CM
*
*
*   file - Navigation.vue
*   dependencies - NONE
*
*
******************************************************************-->
<template>
    <div class="nav-outer">
        <div class="logo-outer">
            <router-link to="/">
                <!-- <img src="@/assets/images/placeholder-logo-50.png" alt="logo"> 1.01 -->
            <div id="logo">Fairview Tourist Park</div>
            </router-link>
        </div>
        <div class="menu-outer">
            <router-link to="/about" ><div class="menu-item">About us</div></router-link>
            <router-link to="/book"><div class="menu-item">Book plot</div></router-link>
            <!-- <router-link to="/gallery"><div class="menu-item">Gallery</div></router-link> -->
        </div>
        <div class="hamburger-cross" v-on:click="openClose"> <!--1.0.2-->
            <img id="hamburger-cross-img" :src="`${menuImg}`" alt="hamburger menu"> <!--1.0.2-->
        </div>
    </div>
    <div id="nav-hamburger">
        <router-link to="/" v-on:click="close"><div class="nav-hamburger-row">Home</div></router-link>
        <router-link to="/about" v-on:click="close"><div class="nav-hamburger-row">About us</div></router-link>
        <router-link to="/book" v-on:click="close"><div class="nav-hamburger-row">Book plot</div></router-link>
        <router-link to="/gallery" v-on:click="close"><div class="nav-hamburger-row">Gallery</div></router-link>
    </div>
</template>

<style lang="scss">
    .nav-outer
    {
        width: 100%;
        background: yellowgreen;
        border-bottom: #59791a 1px solid; //1.0.2
        height: 50px;
        padding: 0;
        margin:0px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        font-size: 25px;
        color: #ffffff;
    }
    .menu-outer 
    {
        width: 50%;
        /* height: 100%; */
        display: flex;
        justify-content: space-evenly;
        /* background: blue; */
        @media only screen and (max-width: 710px) 
        {
            display: none
        }
    }
    .menu-item{
        font-size: 20px;
    }
    .logo-outer 
    {
        width: 50%;
        display: flex;
        // justify-content: center; //temp
        justify-content: flex-start;
        align-items: center;
        margin-left: 10%; //temp
        margin-right: -10%; //temp
        img{ // 1.0.1
            // margin: auto;
            width: 50px;
            height: 50px;
        }
    }
    #logo
    {
        margin-left: 10px; //1.01
    }
    a
    {
        text-decoration: none;
        color: #ffffff;
        transition: 0.5s;
    }
    a:hover
    {
        color: #dddddd;
    }
    #nav-hamburger
    {
        display: none;
        // flex-direction: column;
        // position: absolute; for on top
        // top: 50px;
        // z-index: 10;
        // width: 100%;

    }
    .nav-hamburger-row
    {
        height: 40px;
        background: yellowgreen;
        border-bottom: #59791a 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .hamburger-cross
    {
        height: 30px;
        width: 30px;
        img{
            width: 30px;
            height: 30px;
        }
        @media only screen and (min-width: 711px) 
        {
            display: none
        }
    }
    .is-hidden{
        display: flex !important;
        flex-direction: column;
        transition: 300ms;
    }
</style>

<script>
let menuOpen = false
let menuImg = 'img/bars-solid.png'; //1.0.2
export default {
    name: 'Nav',
    data () { //1.0.2
        return {
            menuImg:menuImg
        }
    },
    methods:{
        openClose(){
            
            if(menuOpen)
            {
                let menu = document.getElementById('nav-hamburger');
                this.menuImg ='img/bars-solid.png'//1.0.2
                console.log(menu)
                menu.classList.toggle('is-hidden');
                menuOpen = false;
            }
            else
            {
                let menu = document.getElementById('nav-hamburger');
                this.menuImg = 'img/times-solid.png'//1.0.2
                console.log(menu)
                menu.classList.toggle('is-hidden');
                menuOpen = true;
            }
        },
        close(){
            console.log('in close');
            let menu = document.getElementById('nav-hamburger');
            menu.classList.remove('is-hidden');
            menuOpen = false;
            this.menuImg = 'img/bars-solid.png' //1.0.3
        }

    }
}
</script>